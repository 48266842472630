import React from "react";

import { useTranslation } from 'react-i18next';
import '../js/i18n';

const Donation = () => {

    const { t } = useTranslation();

    return (
        <div className="intro donation backdrop">

            <p>{t('donation-01')}<br /><br />
                {t('donation-02')}</p>

            <a href="https://www.paypal.com/donate/?hosted_button_id=ADW4CVHEN5NQQ" target="_blank" rel="noopener noreferrer"><img className="logo" src="/content/logo/low-res/FleshTV_logo_chrome_250.png" loading="lazy"
                alt="FLESHTV logo" width="250" height="214" /></a>

            <p className="center__text">
                <br />
                <a href="https://www.paypal.com/donate/?hosted_button_id=ADW4CVHEN5NQQ" target="_blank" rel="noopener noreferrer">donate here</a>
                <br />
            </p>



            {/*             <p className="center__text text__small"> <a href="mailto:hello@fleshtv.world">hello@fleshtv.world</a></p>
 */}


            <p className="center__text text__small">  {t('donation-03')}</p>





        </div>
    )
}

export default Donation;