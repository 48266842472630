import React, { useState, useEffect } from "react";

import SpeechAlbumFR from "./SpeechAlbumFR";
import SpeechAlbumEN from "./SpeechAlbumEN";
import TrackList from "./TrackList";
import Payment from "./Payment";
import Credits from "./Credits";

import { useTranslation } from 'react-i18next';
import '../js/i18n';

import Aos from "aos";
import 'aos/dist/aos.css';

import gsap from 'gsap';
import ButtonBuy from "./ButtonBuy";
import ButtonClose from "./ButtonClose";

import Arash from "./artists-bio/Arash";
import Barking from "./artists-bio/Barking";
import Carl from "./artists-bio/Carl";
import AGF from "./artists-bio/AGF";
import Cedrik from "./artists-bio/Cedrik";
import Colin from "./artists-bio/Colin";
import Dave from "./artists-bio/Dave";
import Dylan from "./artists-bio/Dylan";
import Felicity from "./artists-bio/Felicity";
import German from "./artists-bio/German";
import Helene from "./artists-bio/Helene";
import Jonas from "./artists-bio/Jonas";
import Loreles from "./artists-bio/Loreles";
import Merzbow from "./artists-bio/Merzbow";
import Neurokill from "./artists-bio/Neurokill";
import OxhySusu from "./artists-bio/OxhySusu";
import Robbie from "./artists-bio/Robbie";
import RymSasha from "./artists-bio/RymSasha";
import Sukitoa from "./artists-bio/Sukitoa";
import THAA from "./artists-bio/THAA";

const AlbumSection = () => {

    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    useEffect(() => {
        Aos.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: true,
        });
    })

    return (

        <main>

            <div className="compilation__container" >

                <div className="compilation__title" data-aos="fade">
                    <p>Flesh TV V/A 01</p>
                </div>


                <TrackList />


                <picture>
                    <source className="picture" srcSet="/content/pictures/bundle/webp/FleshTV-bundle-01.webp" type="image/webp" />
                    <img className="picture" src="/content/pictures/bundle/jpeg/FleshTV-bundle-01.jpg" loading="lazy"
                        alt="FLESHTV book bundle" width="1000" height="667" />
                </picture>

                <Payment />


                <picture>
                    <source className="picture" srcSet="/content/pictures/bundle/webp/FleshTV-nose-ring.webp" type="image/webp" />
                    <img className="picture " src="/content/pictures/bundle/jpeg/FleshTV-nose-ring.jpg" loading="lazy"
                        alt="FLESHTV cow nosering" width="1000" height="667" />
                </picture>

                <article className="intro backdrop" data-aos="fade">

                    {currentLanguage === 'en' ? <SpeechAlbumEN /> : <SpeechAlbumFR />}
                    <br /><br />    <hr /><br /> <br />

                    <p data-aos="fade">{t('album-text-01')}</p><br />
                    <p className="italic" data-aos="fade">{t('album-text-02')}</p><br />
                    <p data-aos="fade">{t('album-text-03')}</p><br />
                    <p data-aos="fade">{t('album-text-04')}</p><br />
                    <p className="italic" data-aos="fade">{t('album-text-05')}</p><br />
                    <p data-aos="fade">{t('album-text-06')}</p><br />
                    <p data-aos="fade">{t('album-text-07')}</p><br />
                    <p data-aos="fade">{t('album-text-08')}</p><br /><br />
                    <hr /><br /><br />

                    <p className="text__small" data-aos="fade">{t('album-text-bottom')}</p>

                </article>

                <picture>
                    <source className="picture" srcSet="/content/pictures/bundle/webp/FleshTV-book-03.webp" type="image/webp" />
                    <img className="picture" src="/content/pictures/bundle/jpeg/FleshTV-book-03.jpg" loading="lazy" alt="FLESHTV book"
                        width="1000" height="667" />
                </picture>

                <picture>
                    <source className="picture" srcSet="/content/pictures/bundle/webp/FleshTV-USB-01.webp" type="image/webp" />
                    <img className="picture" src="/content/pictures/bundle/jpeg/FleshTV-USB-01.jpg" loading="lazy"
                        alt="FLESHTV USB stick" width="1000" height="667" />
                </picture>

                <picture>
                    <source className="picture" srcSet="/content/pictures/bundle/webp/FleshTV-book-02.webp" type="image/webp" />
                    <img className="picture" src="/content/pictures/bundle/jpeg/FleshTV-book-02.jpg" loading="lazy"
                        alt="FLESHTV USB stick" width="1000" height="667" />
                </picture>

                <Credits />

                <AGF />
                <Arash />
                <Barking />
                <Carl />
                <Cedrik />
                <Colin />
                <Dave />
                <Dylan />
                <Felicity />
                <German />
                <Helene />
                <Jonas />
                <Loreles />
                <Merzbow />
                <Neurokill />
                <OxhySusu />
                <Robbie />
                <RymSasha />
                <Sukitoa />
                <THAA />

            </div>

            {/*       <ButtonBuy /> */}

        </main>


    );
}

export default AlbumSection;