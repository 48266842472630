import React, { useEffect } from "react";
import gsap from "gsap";

import Aos from "aos";
import 'aos/dist/aos.css';

const TrackList = () => {

    const animateArtistClick = (artistClass, textClass) => {
        document.querySelector(artistClass).addEventListener('click', function () {
            gsap.to(textClass, { autoAlpha: 1, pointerEvents: "auto", duration: 1, zIndex: 20, ease: "power3.inOut" });
            gsap.to('.button__close', { autoAlpha: 1, pointerEvents: "auto", duration: 1, zIndex: 21, ease: "power3.inOut" });
            gsap.to('#buttons_top_container', { autoAlpha: 0, duration: 1, ease: "power3.inOut" });
            gsap.to('body, html', { overflowY: "hidden" });
            gsap.to('.compilation__title', { opacity: 0, duration: 1, ease: "power3.inOut" });

            gsap.to('.button__back', { autoAlpha: 0, duration: 1, ease: "power3.inOut" });
            gsap.to('.button__buy', { autoAlpha: 0, duration: 1, ease: "power3.inOut" });

        });
    };

    useEffect(() => {
        animateArtistClick('.oxhy', '.oxhy__text');
        animateArtistClick('.thaa', '.thaa__text');
        animateArtistClick('.jonas', '.jonas__text');
        animateArtistClick('.barking', '.barking__text');
        animateArtistClick('.loreles', '.loreles__text');
        animateArtistClick('.colin', '.colin__text');
        animateArtistClick('.cedrik', '.cedrik__text');
        animateArtistClick('.felicity', '.felicity__text');
        animateArtistClick('.dave', '.dave__text');
        animateArtistClick('.arash', '.arash__text');
        animateArtistClick('.german', '.german__text');
        animateArtistClick('.robbie', '.robbie__text');
        animateArtistClick('.merzbow', '.merzbow__text');
        animateArtistClick('.carl', '.carl__text');
        animateArtistClick('.neurokill', '.neurokill__text');
        animateArtistClick('.rym', '.rym__text');
        animateArtistClick('.sukitoa', '.sukitoa__text');
        animateArtistClick('.dylan', '.dylan__text');
        animateArtistClick('.helene', '.helene__text');
        animateArtistClick('.agf', '.agf__text');

        // Cleanup event listeners on component unmount
        return () => {
            // Remove event listeners
            // Example: document.querySelector('.oxhy').removeEventListener('click', handlerFunction);
        };
    }, []);

    useEffect(() => {
        Aos.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: true,
        });
    })

    return (

        <div className="tracks__container">

            <div className="artist__container backdrop oxhy" data-aos="fade" >
                <div className="track "><span>Oxhy + Susu Laroche</span> · My love to cows</div>
                <div className="asso"><a href="https://www.alfsg.org.uk/" target="_blank" rel="noreferrer">Animal Liberation Front</a></div>
            </div>

            <div className="artist__container backdrop thaa" data-aos="fade" >
                <div className="track "><span>THAA (Andrew Anderson + Thor Harris)</span> · Sanctuary</div>
                <div className="asso"><a href="https://www.austinfarmsanctuary.org/" target="_blank" rel="noreferrer">Austin Farm Sanctuary</a>
                </div>
            </div>

            <div className="artist__container backdrop jonas" data-aos="fade" >
                <div className="track "><span>Jonáš Gruska</span> · Others</div>
                <div className="asso"><a href="https://unoffensiveanimal.is/" target="_blank" rel="noreferrer">Unoffensive Animal</a></div>
            </div>

            <div className="artist__container backdrop barking" data-aos="fade" >
                <div className="track "><span>Barking</span> · Psyche</div>
                <div className="asso"><a href="https://www.farmtransparency.org/" target="_blank" rel="noreferrer">Farm Transparency
                    Project</a></div>
            </div>

            <div className="artist__container backdrop loreles" data-aos="fade" >
                <div className="track "><span>Loreles</span> · Hundreds of pairs of eyeballs staring back at me</div>
                <div className="asso"><a href="https://traslosmuros.com/" target="_blank" rel="noreferrer">Tras los Muros/Aitor Garmendia</a></div>
            </div>

            <div className="artist__container backdrop colin" data-aos="fade">
                <div className="track "><span>Colin Andrew Sheffield</span> · Moments apart</div>
                <div className="asso"><a href="https://www.pigspeace.org/main/index.html" target="_blank" rel="noreferrer">Pigs Peace Sanctuary</a>
                </div>
            </div>

            <div className="artist__container backdrop cedrik">
                <div className="track "><span>Cedrik Fermont</span> · They live</div>
                <div className="asso"><a href="https://www.agripunk.com/" target="_blank" rel="noreferrer">Agripunk</a></div>
            </div>

            <div className="artist__container backdrop dave" data-aos="fade">
                <div className="track "><span>Dave Phillips</span> · We are our relationships</div>
                <div className="asso"><a href="https://sentience.ch/de/" target="_blank" rel="noreferrer">Sentience Politics</a></div>
            </div>

            <div className="artist__container backdrop german" data-aos="fade">
                <div className="track "><span>German Army</span> · Discarded devices</div>
                <div className="asso"><a href="https://www.newlifeanimalsanctuary.org/" target="_blank" rel="noreferrer">New life Animal
                    Sanctuary</a>
                </div>
            </div>

            <div className="artist__container backdrop carl" data-aos="fade">
                <div className="track "><span>Carl Michael Von Hausswolff</span> · Migrating Birds Hiding In The Rain In Italy
                </div>
                <div className="asso"><a href="https://www.alligatorheadfoundation.org/" target="_blank" rel="noreferrer">Alligator Head
                    Foundation</a>
                </div>
            </div>

            <div className="artist__container backdrop merzbow" data-aos="fade">
                <div className="track "><span>Merzbow</span> · Babybird</div>
                <div className="asso"><a href="https://arcj.org/en/" target="_blank" rel="noreferrer">Animal Rights Center</a></div>
            </div>

            <div className="artist__container backdrop arash" data-aos="fade">
                <div className="track "><span>Arash Pandi</span> · Finally, she is with us (for Vera)</div>
                <div className="asso"><a href="https://www.zendegicharity.com/" target="_blank" rel="noreferrer">Charkhe Zendegi</a></div>
            </div>

            <div className="artist__container backdrop neurokill" data-aos="fade">
                <div className="track "><span>Neurokill</span> · Por los animales</div>
                <div className="asso"><a href="https://www.instagram.com/gatosolvidados/" target="_blank" rel="noreferrer">Gatos Olvidados</a>
                </div>
            </div>

            <div className="artist__container backdrop felicity" data-aos="fade">
                <div className="track "><span>Felicity Mangan</span> · Memory Lapse</div>
                <div className="asso"><a href="https://brightside.org.au/" target="_blank" rel="noreferrer">Brightside Farm Sanctuary</a></div>
            </div>

            <div className="artist__container backdrop robbie" data-aos="fade">
                <div className="track "><span>Robbie Judkins</span> · Game (version)</div>
                <div className="asso"><a href="https://animalrebellion.org/" target="_blank" rel="noreferrer">Animal Rebellion</a></div>
            </div>

            <div className="artist__container backdrop dylan" data-aos="fade">
                <div className="track "><span>Dylan Kerr + Knochengesicht</span> · Voicemail from a corpse rotting in the embers
                    of hell</div>
                <div className="asso"><a href="https://www.huntsabs.org.uk/" target="_blank" rel="noreferrer">Hunt Saboteurs Association</a></div>
            </div>

            <div className="artist__container backdrop sukitoa" data-aos="fade">
                <div className="track "><span>Sukitoa o Namau</span> · We use everything except their squeal</div>
                <div className="asso"><a href="https://www.instagram.com/269.liberation_animale/" target="_blank" rel="noreferrer">269 Libération
                    Animale</a></div>
            </div>

            <div className="artist__container backdrop rym" data-aos="fade">
                <div className="track "><span>Rym Nouioua + Sascha Brosamer</span> · Ultrasonic Squeaks</div>
                <div className="asso"><a href="https://www.spea.pt/en/" target="_blank" rel="noreferrer">SPEA</a></div>
            </div>

            <div className="artist__container backdrop helene" data-aos="fade">
                <div className="track "><span>Hélène Vogelsinger</span> · HigherConnection</div>
                <div className="asso"><a href="https://laroucoulade.fr/" target="_blank" rel="noreferrer">La Roucoulade</a></div>
            </div>

            <div className="artist__container backdrop agf" data-aos="fade">
                <div className="track "><span>AGF</span> · But as !kin</div>
                <div className="asso"><a href="https://animalia.fi/ota-yhteytta/" target="_blank" rel="noreferrer">Animalia</a></div>
            </div>

        </div>

    )

}

export default TrackList;