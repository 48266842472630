import React, { useEffect } from 'react';


const ButtonsSpeechAboutFR = () => {

    useEffect(() => {
        const textAboutFr = `FLESH TV EST UNE PLATEFORME DEDIÉE À NOTRE RAPPORT AU VIVANT ; ELLE VISE À LE REPENSER ET À TENDRE VERS UNE RELATION ÉQUILIBRÉE. LIEU D'EXPLORATION DE PARADIGMES ALTERNATIFS, ELLE ENCOURAGE UN ACTIVISME RENOUVELÉ AU MOYEN DE L'ART, LA RECHERCHE ET L'ESPRIT CRITIQUE.

        Flesh TV est née de la tension entre humanité et animalité. Elle s'empare de ces concepts pour répondre aux défis actuels, appelant à reconsidérer les enjeux philosophiques, éthiques, politiques, économiques, sociaux-culturels que ces notions soulèvent.
        
        L'étude des origines et du développement des humains — anthropogenèse — associée à des disciplines telles que la biologie, l'anthropologie, la psychologie, la philosophie (pour n'en citer que quelques unes) a tracé les contours communément acceptés de notre relation au vivant. À la lisière de pratiques créatives et d'enjeux de société (alimentaire, sanitaire, énergétique, économique, etc...), Flesh TV porte un regard pluriel sur les origines et les conséquences de la séparation de l'humain et de l'animal.
        
        La surexploitation des animaux, de la nature et ses répercussions (extinction d'espèces, destruction des habitats naturels, épuisement des ressources, pollutions, déchets, changement climatique, zoonoses,...) n'invitent pas au simple bilan mais à l'urgence d'aborder cet état des lieux sous des angles nouveaux. Est-il possible d'interroger notre rapport aux autres et au monde en prenant comme point de départ cette scission entre l'humain et l'animal ?
        
        L'humain défini en tant qu' « animal politique », doué de raison et de morale, a consacré son savoir et sa technique à la maîtrise de la nature au détriment d'un équilibre, où co-dépendance et co-existence conditionnent tout fonctionnement écosystémique. L'activité humaine a réduit la biomasse de mammifères terrestres et marins par six et la biomasse végétale de moitié¹ . Aujourd'hui, les humains et les animaux d’élevage comptent pour 96% de la biomasse des mammifères : les humains représentant 36% du totale, les animaux d’élevage, principalement le bétail et le cheptel porcin comptant pour 60%, et les animaux sauvages pour seulement 4%² . Ainsi, près des deux tiers de la biomasse de mammifères repose sur un système productiviste et nataliste, créant de la vie animale dans le seul but de la consommer dès son plus jeune âge.
        
        Flesh TV œuvre au niveau international pour réunir des artistes, penseur·euse·s et chercheur·euse·s impliqué·e·s sur ces sujets. Son engagement politique vise également à mettre en avant des activistes, des collectifs et des organisations défendant les droits de la nature et des animaux. Sa mission principale est de canaliser les forces et les potentiels derrière une communauté grandissante d'acteur·rice·s aspirant à une transformation culturelle de nos sociétés en confrontant l’anthropocentrisme qui les caractérise. Ce changement nécessite une profonde reconfiguration de nos systèmes de pensée et de relation au sensible. Il suppose avant tout d'abandonner nos schémas anti- organiques, mécanistes, destructeurs, basés sur l'exploitation du vivant pour basculer vers une approche systémique, holistique de nos modèles. Dans ce cadre, Flesh TV se constitue en espace de réflexion, de création et d'action offrant la possibilité d'aligner ces pratiques avec ces convictions. Ainsi, au travers de collaborations et partages, il inspire à se laisser traverser par les horizons qui s'y dessinent.
        
        Comment l'humanité a pu se démarquer si distinctement du monde animal, jusqu'à normaliser, pour son unique profit, l'idée que toute vie non-humaine puisse être exploitée ? Par quels processus nous sommes-nous éloignés de la nature jusqu'à la considérer comme une entitée séparée ? Cette rupture constitue-t-elle la racine justifiant la violence structurelle exercée sur d'autres espèces ainsi que sur notre environnement ? En se distinguant de la nature, en s'arrachant de l'équilibre qui la sous-tend, l'humain aurait-il renoncé à une part de sa nature profonde ?
        
        L'agriculture marque le début de notre domestication du vivant. L'humain devenant maître des animaux, une hiérarchie inter-espèces s'est imposée et standardisée. Avec l'avènement des révolutions industrielles, elle a pu s'étendre et se projeter sur l'ensemble de la nature, la transformant en véritable objet de domination et de contrôle. L'agriculture a ainsi inauguré notre conception de la terre comme ressource, principalement caractérisée par sa productivité, sans aucunement se soucier de ses limitations. Comment dialoguer avec le vivant quand nous sacrifions notre relation au sol, à la vie sauvage ou domestiquée ? Si la définition que nous donnons à la vie humaine comme animale se voit réduite à sa valeur économique, jusqu'à nier intégrité et sentience dans certains cas, notre inclination à l’altérité et à l'empathie ne peut qu'en être affectée.
        
        En transformant des écosystèmes entiers en “capital naturel”, l'exploitation et l'extractivisme se sont érigés en principes d'une relation non-réciproque, déséquilibrée, confisquant toute capacité de régénération et de résilience. C'est ainsi que nos modèles économiques ont amorcé une offensive sur le vivant, jusqu'à ce que nos sociétés rejettent l'idée de toute coopération, restitution ou réparation. Cependant, chacun·e de nous détient, par ses choix, le pouvoir de minimiser notre impact négatif ; nos modes de vie demeurant le problème et la solution.
        
        Flesh TV s'inscrit à la lisière de l'art, de la recherche et de l'activisme pour nourrir et encourager un engagement politique en faveur des animaux et de la nature, au-delà des sphères qui lui sont habituellement associées. Toute personne prenant part à la plateforme et à ses divers projets s'identifie, d'une manière ou d'une autre, sur un plan personnel et/ou publique, avec la vision, les idéaux et les espoirs portés par cette initiative. Le panel de collaborateur·rice·s s'étend de celleux qui adoptent une consommation s'opposant à toute exploitation animale et s'engageant de manière responsable vis-à-vis de la planète, à celleux qui font un travail de sensibilisation sur ces questions, à celleux qui, pour finir, se consacrent à des projets entièrement dédiés à la réinvention de notre relation au vivant. En tant que plateforme d'art, label sonore, espace de recherche, en tant que territoire politique transdisciplinaire, nous voulons créer, accueillir, faire germer des idées, des représentations, des attitudes et des habitudes sans lesquelles la transition vers le futur que nous projetons, et que nous nous efforçons d'incarner — à travers nos comportements et nos pratiques — ne pourra jamais advenir.
        
        Le développement des civilisations agraires constitue le socle à partir duquel une justification sociale et culturelle de la suprématie humaine a pu s'imposer. Cette ascendance aux multiples racines (religion, science, technologie, philosophie,...) repose sur l'influence combinée de tous les dispositifs, modèles et disciplines qui dictent nos pensées, actions et préjugés. Que l'on observe son impact sur l'état de la planète ou sur notre traitement des animaux, les limites d'un tel paradigme ne peuvent être ignorées une fois confrontées aux conséquences désastreuses qui lui sont associées. Face à un tel constat, est-il toujours possible de légitimer cette position qui nous autorise, en s'érigeant au-dessus des autres espèces, à prioriser nos besoins et intérêts ? Une telle hiérarchie est-elle encore pertinente lorsqu'elle menace notre propre survie?
        
        Si nous faisons abstraction du débat sur la raison et la conscience comme élément de différentiation entre l'humain et l'animal, il est intéressant de noter que la convention sur laquelle repose la supériorité de l'humain n'a jamais été confrontée à un corpus de savoir consacré au monde animal. Ce que l'on nomme les “sciences animales” aujourd'hui est communément décrit comme “l'étude biologique des animaux qui sont sous le contrôle des humains” ; discipline qui semble s'intéresser exclusivement à l'optimisation du rendement des espèces domestiquées.
        
        Le terme de “bien-être animal” souffre lui-même d'une dimension anthropocentrique dans la mesure où il convoque une perspective utilitariste du droit animal : si les animaux peuvent bénéficier d'une préoccupation morale et se doivent donc d'être traités de manière respectueuse, il est toutefois acceptable, dans le cadre de leur exploitation, de leur infliger des souffrances. Des disciplines telles que l'éthologie — étude du comportement animal — sont relativement récentes (début 1900) et leurs applications sont à la fois versatiles et ambiguës. Ces recherches peuvent ainsi de manière équivoque être sollicitées, tantôt dans le cadre de l'éthique pour défendre l'autonomie et la dignité animale, tantôt par l'industrie agro-alimentaire pour l’amélioration des normes et standards de production sans jamais remettre en question le caractère intensif des infrastructures, ni perdre de vue les objectifs de rentabilité.
        
        De nouveaux paradigmes peuvent-ils émerger de la révision profonde et sensible des concepts d'humanité et d'animalité ? De nouveaux comportements peuvent-ils émaner du réajustement de notre manière d'habiter le monde ? Faisant place ainsi, au partage et à la coopération plutôt qu'à l'exploitation et au pillage, une relation nouvelle pourra-t-elle voir le jour ?
        
        Flesh TV souhaite prendre part à un effort global pour l'avènement d'une nouvelle conscience, marquée par une forme d'humilité cherchant à co-exister plutôt que dominer. Face à une nature riche et complexe, ce même élan vise à en reconnaître les principes fondamentaux pour trouver notre place au sein de ce réseau subtil d'interdépendances. Flesh TV se focalise sur notre relation à l'animal et à la nature comme point de départ pour questionner notre condition et notre rapport au monde. Son objectif est d'amorcer une réflexion sur la définition et l’expérience même du vivant et de ce qu'on nomme encore “humanité“, ce que cela signifie ou ce que nous souhaiterons lui faire signifier.
        
        Pour le formuler de la manière la plus anthropocentrique possible :
        
        Quand commence-t-on ou cesse-t-on d'être humain ?`


        const playElFr = document.getElementById('play-about-fr');
        const pauseElFr = document.getElementById('pause-about-fr');
        //const stopEl = document.getElementById('stop-fr');

        // add UI event handlers
        playElFr.addEventListener('click', playAboutFr);
        pauseElFr.addEventListener('click', stop);
        //stopEl.addEventListener('click', stop);

        function playAboutFr() {



            const utterance = new SpeechSynthesisUtterance(textAboutFr);

            utterance.lang = 'fr-FR';

            utterance.addEventListener('start', handleStart);
            utterance.addEventListener('pause', handlePause);
            utterance.addEventListener('resume', handleResume);
            utterance.addEventListener('end', handleEnd);
            utterance.addEventListener('boundary', handleBoundary);
            window.speechSynthesis.speak(utterance);
        }


        function pauseAboutFr() {
            window.speechSynthesis.pause();
        }

        function stop() {
            window.speechSynthesis.cancel();
            handleEnd();
        }
        function handleStart() {
            playElFr.disabled = true;
            pauseElFr.disabled = false;
            //stopEl.disabled = false;
        }

        function handlePause() {
            playElFr.disabled = false;
            pauseElFr.disabled = true;
            //stopEl.disabled = false;
        }

        function handleResume() {
            playElFr.disabled = true;
            pauseElFr.disabled = false;

        }

        function handleEnd() {
            playElFr.disabled = false;
            pauseElFr.disabled = true;

        }

        function handleBoundary(event) {
            if (event.name === 'sentence') {
                // we only care about word boundaries
                return;
            }

            const wordStart = event.charIndex;

            let wordLength = event.charLength;
            if (wordLength === undefined) {
                // Safari doesn't provide charLength, so fall back to a regex to find the current word and its length (probably misses some edge cases, but good enough for this demo)
                const match = textAboutFr.substring(wordStart).match(/^[a-z\d']*/i);
                wordLength = match[0].length;
            }

            // wrap word in <mark> tag
            const wordEnd = wordStart + wordLength;
            const word = textAboutFr.substring(wordStart, wordEnd);
            const markedText = textAboutFr.substring(0, wordStart) + '<mark>' + word + '</mark>' + textAboutFr.substring(wordEnd);
            //textEl.innerHTML = markedText;
        }



    })

    return (
        <div className="speech-buttons">
            <button id="play-about-fr">lire</button>
            <button id="pause-about-fr" disabled>arreter</button>
        </div>

    )

}

export default ButtonsSpeechAboutFR;