import React from 'react';

export default function FakeLoaderCss() {

    return (
        <div className="loader">
            <div className="sub-loader flicker-loader"></div>
            <p className="textloader">loading</p>
        </div>
    );
}