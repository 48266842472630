import React, { useEffect } from 'react';

import PayPalButton from './PayPalButton'; // Adjust the path as necessary


import { useTranslation, Trans } from 'react-i18next';
import '../js/i18n';

import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';


import PaypalPaymentReact from './PaypalPaymentReact';

const Payment = () => {

    /*   const { t } = useTranslation(); */

    /*   useEffect(() => {
          const script = document.createElement('script');
          script.src = "https://www.paypal.com/sdk/js?client-id=BAAv-CgwHDrfGljD-xCIW44zMuUQrVDcjLVm_m8qaIXtol3IR4_LT2l8LuXdNmESUCu-Dy-Vs4KJgjz0b8&disable-funding=venmo&currency=EUR";
          script.async = true;
          script.onload = () => {
              if (window.paypal) {
                  window.paypal.HostedButtons({
                      hostedButtonId: "ZKNEGQMRLREBA"
                  })
                      .render("#paypal-container-ZKNEGQMRLREBA")
              }
          }
  
          document.body.appendChild(script);
  
      }, []); */

    return (
        <div>
            <div className="intro  payment backdrop">

                <p className="center__text">
                    <span className="larger__text font__title">Flesh TV V/A 01</span><br /><br />
                </p>

                <p>
                    <Trans i18nKey="payment-01" components={{ br: <br /> }} />
                    <br /><br />
                </p>

                {/*  <a href="https://www.paypal.com/ncp/payment/ZKNEGQMRLREBA" target="_blank" rel="noopener noreferrer"><img className="logo" src="/content/logo/low-res/FleshTV_logo_chrome_250.png" loading="lazy"
                alt="FLESHTV logo" width="250" height="214" /></a> */}

            </div>

            <div className="intro payment backdrop">

                {/*    <p className="center__text">
                    <a href="https://www.paypal.com/ncp/payment/ZKNEGQMRLREBA" target="_blank" rel="noopener noreferrer">[buy here]</a>
                </p> */}


                {/*  <button >buy</button> */}



                {/*     <form action="https://www.paypal.com/ncp/payment/ZKNEGQMRLREBA" method="post" target="_top" style={{
                    display: 'inline-grid',
                    justifyContent: 'center',
                    alignContent: 'start',
                    gap: '0.5rem'
                }}>

                    <input class="pp-S4GPESJBLZYZU" type="submit" value="buy" />

                    <img src="https://www.paypalobjects.com/images/Debit_Credit_APM.svg" alt="cards" style={{
                        height: "0.875rem",
                        verticalAlign: "middle"
                    }} />

                </form> */}





                <p className="center__text">
                    <br />
                    <a href="https://www.paypal.com/ncp/payment/ZKNEGQMRLREBA" target="_blank" rel="noopener noreferrer">
                        <Trans i18nKey="payment-02" components={{
                            br: <br />
                        }} />    </a >
                </p>


                <p className="center__text text__small">
                    <Trans i18nKey="payment-04" components={{
                        br: <br />
                    }} />
                    <br />
                    <br />
                </p>

                <a href="https://www.paypal.com/ncp/payment/ZKNEGQMRLREBA" target="_blank" rel="noopener noreferrer"> <img src="https://www.paypalobjects.com/images/Debit_Credit_APM.svg" alt="cards" style={{
                    height: "1.2rem",
                    verticalAlign: "middle"
                }} /></a >


                {/*    <div id="paypal-container-ZKNEGQMRLREBA"></div> */}

                <p className=" text__small">
                    <br /> <br />
                    <hr />
                    <br />
                    <Trans i18nKey="payment-03" components={{
                        br: <br />
                    }} />
                </p>



                {/*     <p className="center__text">
                <Trans i18nKey="payment-02" components={{
                    br: <br />,

                }} />

                <br /><br /> 
 
                <a href="https://www.paypal.com/ncp/payment/ZKNEGQMRLREBA" target="_blank" rel="noopener noreferrer">buy here</a>

               </p> */}

                {/*                 <PayPalButton />
 */}

                {/*                 <div id="paypal-button-container"></div>
 */}
                {/*   <PaypalPaymentReact /> */}
            </div>
        </div>
    )

}

export default Payment;